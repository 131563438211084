<template>
	<div>
		<v-text-field
			:disabled="isDisabled"
			:value="value"
			:type="question.input_type"
			@change="(value) => $emit('update', value)"
		></v-text-field>
	</div>
</template>

<script type="text/javascript">
export default {
	name: "Input",
	props: {
		id: {},
		value: {},
		disabled: {},
	},
	computed: {
        question(){
            return this.$store.state.questionTemplates.data[this.id]
        }, 
		isDisabled(){
			return this.disabled || this.question.disable_input
		}
    },
};
//
</script>
"
